import React from "react";
import Link from "./common/Link";

import { formatWritingsDate } from "../lib/utils";

interface WritingsCardProps {
  createDate?: Date;
  title?: string;
  description?: string;
  link?: string;
}

const WritingsCard = ({
  createDate = new Date(),
  title = "Test Title",
  description = "Test Description",
  link = "writings",
}: WritingsCardProps) => {
  return (
    <div>
      <p className="text-sm text-gray-500">
        <time dateTime={formatWritingsDate(createDate)}>
          {formatWritingsDate(createDate)}
        </time>
      </p>
      <a href={link} className="mt-2 block">
        <p className="text-xl font-semibold text-gray-900">{title}</p>
        <p className="mt-3 text-base text-gray-500">{description}</p>
      </a>
      <div className="mt-3">
        <Link
          to={link}
          className="text-base font-semibold text-indigo-600 hover:text-indigo-500"
        >
          Read full story
        </Link>
      </div>
    </div>
  );
};

export default WritingsCard;
