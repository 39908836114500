import React from "react";
import { graphql } from "gatsby";

import { isObjADate } from "../../lib/utils";

import PageHeader from "../../components/PageHeader";
import WritingsCard from "../../components/WritingsCard";
import withLayout from "../../components/hoc/withLayout";

import { WritingData } from "../../models/Writing";

interface WritingProps {
  writingsCollection: WritingData[];
}

const Writing = ({
  data: {
    allMarkdownRemark: { nodes: writingsCollection },
  },
}) => {
  return (
    <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
        <PageHeader header="Writings" subheader="Recorded thoughts" />
        <div className="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
          {writingsCollection.map((writing, index) => {
            const { date, title, description, slug } = writing.frontmatter;
            return (
              <WritingsCard
                key={index}
                createDate={isObjADate(date) ? date : new Date(date)}
                title={title}
                description={description}
                link={slug}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withLayout(Writing);

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/writings/" } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        id
        timeToRead
        excerpt(pruneLength: 250)
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          slug
          title
          description
        }
      }
    }
  }
`;
